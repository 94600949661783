import React, {useState} from 'react';
import './Landing.css';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';


function Landing() {

    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    function validateEmail() {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (regex.test(email)) {
            setMessage('Successful registration. You will soon hear our news =)');
        } else {
            setMessage('Invalid email.');
        }
    }


    return (
        <div className="homepage">
            <div className="title_landing">
                <h1>Welcome to AI Virtual Influencer</h1>
                <p>Create stunning videos with the power of AI. Designed for influencers.</p>
            </div>

            <input
                type="email"
                className="email-input"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />
            <button className="signup-button" onClick={validateEmail}>Sign up for the beta</button>
            <div className={message === 'Invalid email.' ? 'message-invalid' : 'message-valid'}>
                {message}
            </div>
            <div className="cards">
                <div className="card">
                    <h2>Create your AI Avatar</h2>
                    <h3>Bring your digital persona to life</h3>
                    <p>Craft a unique digital identity with our AI Avatar creation tool. Dive into a world where your
                        ideas get a virtual form. Make your mark in the virtual world!</p>
                </div>
                <div className="card">
                    <h2>Customize your Avatar</h2>
                    <h3>Personalize to Perfection</h3>
                    <p>Your AI Avatar, Your Rules! Enjoy the ultimate freedom in customizing your digital counterpart.
                        Choose between male or female appearances, select from a diverse range of voices, and fine-tune
                        every detail to match your desired persona.
                        Whether it's for professional branding or personal expression, your AI Avatar can be tailored to
                        fit any role, any mood, any moment.
                    </p>
                </div>
                <div className="card">
                    <h2>Engage on Social Media</h2>
                    <h3>Make an Impact Across Platforms</h3>
                    <p>Unleash your AI Avatar on the world's stage! Seamlessly integrate your customized digital
                        influencer across various social media platforms.
                        Engage your audience with a unique blend of AI-driven content that's always on-brand and
                        on-trend.
                        Whether it's for product promotions, interactive campaigns, or just sharing your digital
                        journey, your AI Avatar is your key to making a lasting impression online.
                    </p>
                </div>
            </div>
            <div>
                <Button onClick={handleOpen}>Privacy Policy</Button>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={modalStyle}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Privacy policy
                        </Typography>
                        <Typography id="modal-modal-description" sx={{mt: 2}}>
                            {'Privacy policy blablabla'}
                        </Typography>
                    </Box>
                </Modal>
            </div>
        </div>
    );
}

export default Landing;